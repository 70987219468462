<app-header></app-header>
<section class="xp-main"> 
  <img src="../../../assets/images/5-1-1200x700.jpg"/>
  <div  class="xp-main-header">
      <h1>Digital Solutions</h1>
      <p>
     A first in the market, our POS’s enable bill payments <br/>
      through any of the local mobile money service providers                                        
      </p>
      <!-- <a [routerLink]="['/register']">GET STARTED</a> -->
  </div>
</section>
<section class="xp-section xp-payments" style="background-color: #fff;">
    <br><br><br>
  <div class="row">
    <div class="col-md-3">
      <div class="xp-payment-left">
        <img  src="../../../assets/images/services_3-530x340.jpg" />
      </div>
    </div>
    <div class="col-md-9">
      <div class="xp-payment-right">
        <h1>An All-in-One Web Solution <br/> for Your Business</h1>
        <div style="text-decoration: overline; width: 200px;height: 20px; color: #ffffff;"></div>
        <p>Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward.
        </p>
        <p>The Blu Penguin is a digital automation solutions company, passionate about equipping our clients with next-gen payment platforms that will disrupt conventions and revolutionize the way they do business.
        </p>
      </div>
    </div>
  </div>
  <br />
  <br />
  <hr style="color: white; background: rgb(206, 200, 200); height: 1.5px;">
  <br />
  <br />
  <div class="row">
    <div class="col-md-9">
      <div class="xp-payment-left">
        <h1>Secure Platform for Online Payments</h1>
        <br/>
        <p>Built on modern technology that enables easy, quick and secure integration to your
          websites.

          With our detailed API manual, developers can effortlessly integrate payments to
          websites using our plugins, libraries and extensions.</p>
      </div>
    </div>
    <div class="col-md-3">
      <div class="xp-payment-right">
        <img src="../../../assets/images/securepayment.png" />
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>