<section class="charts">
  <div class="container-fluid">
    <!-- Page Header-->
    <header>
      <h1 class="h3 display">Overview</h1>
    </header>
    
  </div>
</section>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
