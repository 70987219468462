<app-header></app-header>
<div class="terms">
  <br/><h2>XPRESSPAY TERMS OF SERVICE</h2>
  <p>These Merchant Terms of Service is an agreement between you and Xpress Payment. It details Xpress Payment’s
    obligations to you. It also highlights certain risks in using the services, and you must consider such risks
    carefully as you will be bound by the provision of this Agreement through your use of this website or any of
    our services.

    PLEASE READ AND UNDERSTAND THE TERMS OF SERVICE CAREFULLY BEFORE YOU AGREE TO BE BOUND BY ITS TERMS.</p>

  <br/><h2>About Us</h2>

  <p>Xpresspay is an online payment gateway that allows merchants and business owners to receive payments from
    their customers instantly through various payment methods.</p>

  <br/><h2>Registration</h2>

  <p>To use Xpresspay, you have to create an Xpresspay account by registering. To register, you will provide us
    with certain information such as your email, first name, last name, business name and phone number and we
    may seek to verify your information, (by ourselves or through third parties), after which we will approve
    your account unless deemed risky. By accepting these terms and conditions, you give us permission to do all
    these.</p>

  <br/><h2>Age Restriction</h2>

  <p>BY using our services, you confirm that you are of the age of 18 and above as our website and services are
    not directed to users younger than the age of 18 years. We do not knowingly engage with children under the
    age of 18.</p>

  <br/><h2>Intellectual Property</h2>

  <p>Unless stated to the contrary, Xpress Payments and/or its licensors owns all intellectual property rights
    and materials on this website subject to the license granted below. owned by XpressPay and/or its licensors.
    We do not grant you any right, license, title or interest to any of our intellectual Property rights which
    you may or may not have access to. You agree to take such actions including any legal or official document
    or other documents that may be needed to further affirm our intellectual property rights.</p>

  <br/><h2>Who is Authorized to Subscribe to our Service?</h2>

  <p>You may use the Services only if you agree to form a binding contract with Xpress Payments and are not a
    person barred from receiving services under the laws of the applicable jurisdiction. If you are accepting
    these Terms and using the Services on behalf of a company, business, or organization, you represent and
    warrant that you are authorized to do so.</p>





  <br/><h2>Change of Information</h2>

  <p>In the event that you change any information provided to us at registration including your business name,
    address, financial institution, mode of payments or the products and services that you offer, or where a
    corporate restructuring occurs you agree to notify us within 14 days of such change. We may be unable to
    respond to you if you contact us from an address, telephone number or email account that is not registered
    with us.</p>

  <br/><h2>Representation and Warranties</h2>

  <p>You represent and warrant to Xpress Payments that:
    1. You have full power and authority to enter into, execute, deliver and perform this Agreement;
    2. You are duly authorized and in good standing under the laws of the Federal Republic of Nigeria or any
    state, region or country of your organisation and are duly authorised to do business in all other states,
    regions or countries in which your business operates.</p>

  <br/><h2>Account Security</h2>

  <p>You agree not to allow anyone else to have or use your password details and to comply with all reasonable
    instructions we may issue regarding account access and security. In the event you share your password
    details, Xpress Payments will not be liable to you for losses or damages. You will also take all reasonable
    steps to protect the security of the personal electronic device through which you access Xpress Payments’
    services (including, without limitation, using PIN and/or password protected personally configured device
    functionality to access Xpress payments’ services and not sharing your device with other people).</p>

  <br/><h2>Data Compliance</h2>

  <p>You agree to comply with all data privacy and security requirements of the Payment Card Industry Data
    Security Standard (PCI DSS Requirements”) and under any applicable law or regulation that may be in force,
    enacted or adopted regarding confidentiality, your access, use, storage and disclosure of user information.
    Information on the PCI DSS can be found on the PCI Council’s website. It is your responsibility to comply
    with these standards.</p>

  <p>We are responsible for the security and protection of Cardholder Data (CHD) that we collect and store.
    Accordingly, we implement access control measures, security protocols and standards including the use of
    encryption and firewall technologies to ensure that CHD is kept safe and secure on our servers, in
    compliance with the PCI DSS Requirement. We also implement periodical security updates to ensure that our
    security infrastructures are in compliance with reasonable industry standards.</p>

  <p>We acknowledge that you own all your customer data. You, however, hereby grant Xpress Payments a perpetual,
    sub-licensable, assignable, worldwide, royalty-free license to use, reproduce, electronically distribute,
    and display your customers’ data for the following purposes:

    1. Providing and improving our services;
    2. Internal usage, including but not limited to, data analytics and metrics so long as individual customer
    data has been anonymized and aggregated with other customer data;
    3. Complying with applicable legal requirements and assisting law enforcement agencies by responding to
    requests for the disclosure of information in accordance with local laws; and
    4. any other purpose for which consent has been provided by your customer.</p>

  <br/><h2>Software Licence </h2>

  <p>We hereby grant you a revocable, non-exclusive, non-transferable license to use XpressPay’s APIs,
    developer’s toolkit, and other software applications (the “Software”) in accordance with the documentation
    accompanying the Software. This license grant includes all updates, upgrades, new versions and replacement
    software for your use in connection with the Xpress Payments’ services. </p>

  <p>Noncompliance with the documentation and any other requirements provided by Xpress payments, shall result
    in being liable for all resulting damages suffered by you, Xpress Payments and third parties. You agree not
    to alter, reproduce, adapt, distribute, display, publish, reverse engineer, translate, disassemble,
    decompile or otherwise attempt to create any source code that is derived from the Software. Upon expiration
    or termination of this Agreement, you will immediately stop all use of any Software provided through your
    use of XpressPay.</p>

  <br/><h2> Trademark Licence</h2>

  <p>We hereby grant you a revocable, non-exclusive, non-transferable license to use Xpress Payments’ trademarks
    used to identify our services (the “Trademarks”) solely in conjunction with the use of our services. You
    agree that you will not at any time during or after this Agreement assert or claim any interest in or do
    anything that may adversely affect the validity of any Trademark or any other trademark, trade name or
    product designation belonging to or licensed to Xpress Payments (including, without limitation registering
    or attempting to register any Trademark or any such other trademark, trade name or product designation).
    Upon expiration or termination of this Agreement, you will immediately stop all display, advertising and use
    of all the Trademarks.</p>


  <br/><h2>Intellectual Property</h2>

  <p>We do not grant any right or license to any Xpress Payment’s intellectual property rights by implication,
    estoppel or otherwise other than those expressly mentioned in this Agreement.</p>

  <p>Each party shall retain all intellectual property rights including all ownership rights, title, and
    interest in and to its own products and services, subject only to the rights and licenses specifically
    granted herein.</p>

  <p>You hereby grant Xpress Payments permissions to use your name and logo in our marketing materials
    including, but not limited to use on our website, in customer listings, in interviews and in press releases.
    Such publicity does not imply an endorsement for your products and services. </p>


  <br/><h2>Confidentiality Information</h2>

  <p>The parties acknowledge that in the performance of their duties under this Agreement, either party may
    communicate to the other (or its designees) certain confidential and proprietary information, including
    without limitation information concerning each party’s services, know how, technology, techniques, or
    business or marketing plans (collectively, the “Confidential Information”) all of which are confidential and
    proprietary to, and trade secrets of, the disclosing party. </p>

  <p>Confidential Information does not include information that: (i) is public knowledge at the time of
    disclosure by the disclosing party; (ii) becomes public knowledge or known to the receiving party after
    disclosure by the disclosing party other than by breach of the receiving party’s obligations under this
    section or by breach of a third party’s confidentiality obligations; (iii) was known by the receiving party
    prior to disclosure by the disclosing party other than by breach of a third party’s confidentiality
    obligations; or (iv) is independently developed by the receiving party.</p>

  <p>As a condition to the receipt of the Confidential Information from the disclosing party, the receiving
    party shall: (i) not disclose in any manner, directly or indirectly, to any third party any portion of the
    disclosing party’s Confidential Information; (ii) not use the disclosing party’s Confidential Information in
    any fashion except to perform its duties under this Agreement or with the disclosing party’s express prior
    written consent; (iii) disclose the disclosing party’s Confidential Information, in whole or in part, only
    to employees and agents who need to have access thereto for the receiving party’s internal business
    purposes; (iv) take all necessary steps to ensure that its employees and agents are informed of and comply
    with the confidentiality restrictions contained in this Agreement; and (v) take all necessary precautions to
    protect the confidentiality of the Confidential Information received hereunder and exercise at least the
    same degree of care in safeguarding the confidential information as it would with its own confidential
    information, and in no event shall apply less than a reasonable standard of care to prevent disclosure.</p>

  <br/><h2>Due Diligence for Your Customers</h2>

  <p>You agree that, you are solely responsible for verifying the identities of your customers, ensuring that
    they are authorised to carry out the transactions on your platform, and determining their eligibility to
    purchase your products and services. </p>

  <p>You are also required to maintain information and proof of service or product delivery to your customer.
    Where a dispute occurs needing resolution, you may be required to provide Xpress Payments with these. </p>

  <br/><h2>Card Network Rules</h2>

  <p>Each card network has its own rules, regulations and guidelines. You are required to comply with all
    applicable Network Rules that are applicable to merchants. You can review portions of the Network Rules at
    Mastercard, Visa, Verve and other payment cards. The Card Networks reserve the right to amend the Network
    rules.</p>

  <br/><h2>Customer Payments</h2>

  <p>You shall only process payments when authorised to do so by your customer. We will only process
    transactions that have been authorised by the applicable Card Network or card issuer.</p>

  <p>We do not guarantee or assume any liability for transactions authorised and completed that are later
    reversed or charged back. You are solely responsible for all reversed or charged back transactions,
    regardless of the reason for, or timing of, the reversal or chargeback. Xpress Payments may add or remove
    one or more payment types or networks at any time. If we do so we will use reasonable efforts to give you
    prior notice of the removal.</p>

  <br/><h2>Fees and Pricing</h2>

  <p>You agree to pay us for the services we render as a payment gateway for your goods and services. Our fees
    will be calculated as demonstrated on the pricing page on the website or as otherwise agreed between the
    parties in writing. The fees on our pricing page is integral to and forms part of this Agreement. </p>

  <p>We reserve the right to revise our fees at any time without prior notice to you. In the event that we
    revise our fees we will notify you within 5 days of such change. </p>

  <br/><h2>How we handle your Funds</h2>

  <p>You authorize and instruct Xpress Payments to hold, receive, and disburse funds on your behalf when such
    funds from your card transactions settle from the Card Networks. By accepting this Agreement, you further
    authorize Xpress Payments on how your card transaction settlement funds should be disbursed to you and the
    timing of such disbursement. </p>

  <p>You agree that you are not entitled to any interest or other compensation associated with the settlement
    funds held by Xpress Payments pending settlement and disbursement to your Bank Account. </p>

  <p>Settlement funds will be held in a deposit account at Xpress Payments pending disbursement to you in
    accordance with the terms of this contract. We may periodically make available to you information about
    pending settlements yet to be received from the Card Networks. </p>

  <p> Your authorisations will remain valid and be of full effect until your Xpresspay Account is closed or
    terminated.</p>
  <br/><h2>Security and Fraud Controls</h2>

  <p>Xpress Payments is responsible for protecting the security of Payment Data including CHD in our possession
    and will maintain commercially reasonable administrative, technical, and physical procedures to protect all
    the personal information regarding you and your customers that is stored in our servers from unauthorised
    access and accidental loss or modification. Although, we cannot guarantee that unauthorised third parties
    will never be able to defeat those measures or use such personal information for improper purposes. We will
    however take all reasonable and commercially achievable measures to address any security breach as soon as
    we become aware.</p>

  <p>You agree to use other procedures and controls provided by us and other measures that are appropriate for
    your business to reduce the risk of fraud. </p>

  <p>In the event that you suspect any fraudulent activity by a customer, you agree to notify Xpress Payments
    immediately and quit the delivery of the service. In addition, where we suspect that there have been
    frequent fraudulent transactions on your account, we reserve the right to cancel our service to you and/or
    your account.</p>

  <br/><h2>Notification of Errors</h2>

  <p>You agree to notify us immediately any error is detected while reconciling transactions that have occurred
    using XpressPay. We will investigate and rectify the errors where verified. In the event that we notice any
    errors, we will also investigate and rectify such errors. </p>

  <p>Where we owe you money as a result of such errors, we will refund the amount owed to you by a bank transfer
    to your Bank Account. </p>

  <p>If a transaction is erroneously processed through your platform, report to us immediately. We will
    investigate any such reports and attempt to rectify the errors by crediting or debiting your Bank Account as
    appropriate. </p>

  <p>Failure to notify us within 45 (forty-five) days of the occurrence of an error will be deemed a waiver of
    your rights to amounts that are owed to you due to an error.</p>

  <br/><h2>Chargebacks</h2>

  <p>A chargeback occurs when a claim is issued by a credit or debit issuer on behalf a customer to make good
    the loss on a fraudulent or disputed transaction. You may be assessed Chargebacks for (i) customer disputes;
    (ii) suspicious fraudulent transactions; (iii) transactions that do not comply with Card Network Rules or
    the terms of this Agreement or are allegedly unlawful or suspicious; or (iv) any reversals for any reason by
    the Card Network, our processor, or the acquiring or issuing banks. Where a Chargeback occurs, you are
    immediately liable for all claims, expenses, fines and liability we incur arising out of that Chargeback and
    agree that we may recover these amounts by debiting your Bank Account. Where these amounts are not
    recoverable through your Bank Account, you agree to pay all such amounts through any other means.</p>

  <br/><h2>Reserves</h2>

  <p>In our sole discretion, we may place a Reserve on a portion of your funds by holding for a certain period
    such portion where we believe there is a high level of risk associated with your business. If we take such
    steps, we will provide you with the terms of the Reserve which may include the percentage of your funds to
    be held back for a period of time and any other such restrictions that Xpress Payments may deem necessary.
    Where such terms are changed, we will notify you. You agree that you will remain liable for all obligations
    related to your transactions even after the release of any Reserve. In addition, we may require you to keep
    your Bank Account available for any open settlements, chargebacks and other adjustments.</p>

  <p>To secure your performance of this Agreement, you grant Xpress Payments a legal claim to the funds held in
    the Reserve as a lien or security interest for amounts payable by you.</p>

  <br/><h2>Refunds</h2>

  <p>Accepting and processing returns of your products and services will be solely your responsibility. We are
    under no obligation to process returns of your products and services, or to respond to your customers’
    inquiries about returns of your products or services. You agree to submit all Refunds for returns of your
    products and services that were paid for through XpressPay to your customers in accordance with this
    Agreement and relevant Card Network Rules. </p>

  <br/><h2>Termination</h2>

  <p>You are at liberty to terminate this Agreement by closing your XpressPay Account.</p>

  <p>We may restrict your Xpresspay Account and your access to services and any funds, or terminate this
    Agreement, if;

    1. you do not adhere to any of the provisions of this Agreement;
    2. we are mandated to do so by Law;
    3. we are directed by a Card Network or issuing financial institution; or
    4. where a suspicious or fraudulent transaction occurs.</p>


  <br/><h2>Privacy Policy</h2>

  <p>Xpress Payments is committed to ensuring that your Personal Information is securely managed in line with
    global industry best practices. You may refer to our Privacy Policy document for more understanding.</p>


  <br/><h2>Warranty Disclaimer</h2>

  <p>We provide all materials “as is” with no warranty, express or implied, of any kind. We expressly disclaim
    any and all warranties and conditions, including any implied warranty or condition of merchantability,
    fitness for a particular purpose, availability, security, title and non-infringement of intellectual
    property rights, without limiting the generality of the foregoing. </p>

  <p>Xpress Payments makes no warranty that our website and services will meet your requirements or that our
    website will remain free from any interruption, bugs, inaccuracies, and error free. Your use of our services
    is at your own risk and you alone will be responsible for any damage that results in loss of data or damage
    to your computer system. No advice or information, whether oral or written obtained by you from our website
    or our services will create any warranty or condition not expressly stated.</p>



  <br/><h2>Limitation of Liability</h2>

  <p>You agree to the limitation liability clause to the maximum extent permitted by applicable law. Xpress
    Payments will in no way be liable for any direct, indirect, incidental punitive, consequential, special or
    exemplary damages or any damages including damages resulting from revenue loss, profit loss, use, data,
    goodwill , business interruption or any other intangible losses (whether Xpress Payments has been advised of
    the possibility of such damages or not) arising out of Xpress Payment’s website or services (including,
    without limitation to inability to use, or arising from the result of use of Xpress Payment’s website or
    services) whether such damages are based on warranty, tort, contract, statute or any other legal theory.</p>

  <br/><h2>Indemnity</h2>
  <p>You hereby indemnify Xpress Payments and undertake to keep Xpress Payments indemnified against any losses,
    damages, costs, liabilities and expenses (including without limitation to reasonable legal fees) arising out
    of any breach by you of any provision of these Terms or arising out of any claim that you have breached any
    provision of these Terms.</p>

  <br/><h2>Revisions</h2>

  <p>We may revise our Merchant Terms of Service from time to time. We reserve the right to make changes at any
    time. However, prior notice will be given 5 days before implementation to users on this page. Hence, we
    advise you check this page often referring to the date of last modification.</p>

  <p>We will also try to notify you of any material changes which could be done via email associated with your
    account or service notification. By continuing to use our services after the changes become effective, you
    agree to be bound by the revised Terms.</p>

  <br/><h2>Applicable Law </h2>

  <p>These Terms of Use shall be interpreted and governed by the laws of the Federal Republic of Nigeria.</p>

  <br/><h2>Legal Disputes</h2>

  <p>We shall make an effort to settle all disputes amicably. Any dispute arising out of this Agreement which
    cannot be settled, by mutual agreement/negotiation within 10 days shall be referred to arbitration by a
    single arbitrator at the Lagos Multi-Door Courthouse (“LMDC”) and governed by the Arbitration and
    Conciliation Act, Cap A10, Laws of the Federal Republic of Nigeria. The arbitrator shall be appointed by
    both of us (we and you), however, where the Parties fail to agree on the choice of an arbitrator within
    fourteen (14) days, such an arbitrator shall be appointed by the Chairman of the Nigerian Branch of the
    Chartered Institute of Arbitrators, on the application of either Party. </p>

  <p>The findings of the arbitrator and subsequent award shall be binding on both of us. Each of us shall bear
    our respective costs in connection with the Arbitration. Venue for the arbitration shall be Lagos, Nigeria.
  </p>

  <br/><h2>Severability</h2>

  <p>If any portion of these Terms of Use is held by any court or tribunal to be invalid or unenforceable,
    either in whole or in part, then that part shall be severed from these Terms of Use and shall not affect the
    validity or enforceability of any other part in this Terms of Use. </p>

  <br/><h2>Miscellaneous</h2>

  <p>You agree that all agreements, notices, disclosures and other communications that we provide to you
    electronically satisfy any legal requirement that such communications be in writing. Assigning or
    sub-contracting any of your rights or obligations under these Terms of Use to any third party is
    prohibited. We reserve the right to transfer, assign or sub-contract the benefit of the whole or part of
    any rights or obligations under these Terms of Use to any third party.</p>
</div>

<app-footer></app-footer>
