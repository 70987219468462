<div class="breadcrumb-holder">
  <div class="container-fluid">
    <ul class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/dashboard">Home</a></li>
      <li class="breadcrumb-item active">Transactions</li>
    </ul>
  </div>
</div>

<section>
  <div class="container-fluid">
    <br />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h4>Transactions</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table *ngIf="temp_var" id="userTable" class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th class="th-sm">S/N</th>
                    <th class="th-sm">Merchant Id</th>
                    <th class="th-sm">Amount</th>
                    <th class="th-sm">Biller Id</th>
                    <th class="th-sm">Status</th>
                    <th class="th-sm">Terminal Id</th>
                    <th class="th-sm">Unique Key</th>
                    <th class="th-sm">Status</th>
                    <th class="th-sm">Created Date</th>
                    <th class="th-sm">Updated Date</th>
                    <!-- <th class="th-sm">Action</th> -->
                  </tr>
                </thead>
                <tbody>

                  <tr *ngFor="let transaction of transactions; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ transaction.merchantId}}</td>
                    <td>{{ transaction.amount}}</td>
                    <td>{{ transaction.billId}}</td>
                    <td>{{transaction.status}}</td>
                    <td>{{transaction.terminalId}}</td>
                    <td>{{transaction.uniqueKey}}</td>
                    <td>

                      <h5><span class="xp-status status_success" *ngIf="transaction.status =='SUCCESSFUL';">Successful</span></h5>
                      <h5><span class="xp-status status_pending"
                                *ngIf="transaction.status =='PENDING';">Pending</span></h5>
                      <h5><span class="xp-status status_pending"
                                *ngIf="transaction.status =='';">Pending</span></h5>
                      <h5><span class="xp-status status_pending"
                                *ngIf="transaction.status ==null;">Pending</span></h5>


                      <h5 *ngIf="transaction.status =='FAILED';"><span class="xp-status status_failed">Failed</span></h5>
                    </td>
                    <td>{{ transaction.createdAt}}</td>
                    <td>{{ transaction.updatedAt}}</td>

                    <!-- <td><button class="btn btn-primary" (click)="viewUser(user)">View</button></td> -->
                  </tr>
                </tbody>
              </table>
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <!-- <li class="page-item" *ngFor="let page of pages" (click)="listOfUsers(1)"><a class="page-link" href="#">Previous</a></li> -->
                  <li class="page-item" *ngIf=" pageNumber !== 1" (click)="listOfUsers(pageNum -1)"><a class="page-link">Previous</a></li>
                  <li class="page-item" *ngFor="let page of pages" (click)="listOfUsers(page)"><a class="page-link">{{page}}</a></li>

                  <li class="page-item"  *ngIf="lastPage !== true" (click)="listOfUsers(pageNum +1)"><a class="page-link">Next</a></li>
                  <!-- <li class="page-item"><a class="page-link" href="#">Next</a></li> -->
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ngx-spinner
bdColor="rgba(51,51,51,0.8)"
size="medium"
color="#fff"
type="ball-scale-multiple">
<p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
