<!-- navbar-->
<!-- Breadcrumb-->
<div class="breadcrumb-holder">
  <div class="container-fluid">
    <ul class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/dashboard">Home</a></li>
      <li class="breadcrumb-item active">Merchant</li>
    </ul>
  </div>
</div>
<section>
  <div class="container-fluid">

    <header>
      <button data-toggle="modal"
        style="float: left; border-color: #272c4c !important; color: #272c4c !important;" type="button"
        data-toggle="modal" data-target="#inviteUserForm">Create Merchant</button>
      <br />
    </header>

    <div class="row" [ngSwitch]="whichForm">
      <div class="col-lg-12" *ngSwitchCase="'viewMerchant'">
        <div class="card">
          <div class="card-header">
            <h4>Merchant</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table id="userTable" class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th class="th-sm">S/N</th>
                    <th class="th-sm">Alias Id</th>
                    <th class="th-sm">Merchant Id</th>
                    <th class="th-sm">Merchant Category Code</th>
                    <th class="th-sm">Payload Format Indicator</th>
                    <th class="th-sm">Point Of Initiation Method</th>
                    <th class="th-sm">Transaction Currency Code</th>
                    <th class="th-sm">Recipient Name</th>
                    <th class="th-sm">City</th>
                    <th class="th-sm">Country</th>
                    <!-- <th class="th-sm">Action</th> -->
                    <th class="th-sm">Status</th>
                    <th class="th-sm">Response Message</th>
                    <th class="th-sm">Created Date</th>
                    <th class="th-sm">Updated Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let merchant of merchants let i = index">
                    <td>{{ i + 1 }}</td>
                    <td style="color: blue;" (click)="displayUpdateForm(merchant)">{{ merchant.aliasId }}</td>
                    <td>{{ merchant.merchantId }}</td>
                    <td>{{ merchant.merchantCategoryCode }}</td>
                    <td>{{ merchant.payloadFormatIndicator }}</td>
                    <td>{{ merchant.pointOfInitiationMethod }}</td>
                    <td>{{ merchant.transactionCurrencyCode }}</td>
                    <td>{{ merchant.recipientName }}</td>
                    <td>{{ merchant.city }}</td>
                    <td>{{ merchant.country }}</td>
                    <td>

                      <h5><span class="xp-status status_success" *ngIf="merchant.status != null && merchant.status.includes('SUCCESSFUL');">{{ merchant.status}}</span></h5>
                      <h5 *ngIf="merchant.status != null && merchant.status.includes('FAILED');"><span class="xp-status status_failed">{{ merchant.status}}</span></h5>
                      <h5 *ngIf="merchant.status != null && merchant.status.includes('null');"><span class="xp-status status_pending">{{ merchant.status}}</span></h5>
                      <h5 *ngIf="merchant.status != null && merchant.status.includes(null);"><span class="xp-status status_pending">{{ merchant.status}}</span></h5>
                      <h5 *ngIf="merchant.status == '';"><span class="xp-status status_pending">{{ merchant.status}}</span></h5>
                      <h5 *ngIf="merchant.status == null;"><span class="xp-status status_pending">{{ merchant.status}}</span></h5>
                    </td>
                    <td>{{ merchant.errorReason}}</td>
                    <td>{{ merchant.createdAt}}</td>
                    <td>{{ merchant.updatedAt}}</td>

                    <!-- <td><button class="btn btn-primary" (click)="viewUser(user)">View</button></td> -->
                  </tr>
                </tbody>
              </table>
              <div>
                <div class="container">
                  <div class="text-center">
                    <!-- pager -->
                    <nav aria-label="Page navigation example">
                      <ul *ngIf="pager.pages && pager.pages" class="pagination">
                        <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                          <a class="page-link" (click)="newSearch(1, 'paginate')">First</a>
                        </li>
                        <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                          <a class="page-link" (click)="newSearch(pager.currentPage - 1, 'paginate')">Previous</a>
                        </li>
                        <li class="page-item" *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
                          <a  class="page-link" (click)="newSearch(page, 'paginate')">{{page}}</a>
                        </li>
                        <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                          <a  class="page-link" (click)="newSearch(pager.currentPage + 1, 'paginate')">Next</a>
                        </li>
                        <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                          <a class="page-link" (click)="newSearch(pager.totalPages, 'paginate')">Last</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12" *ngSwitchCase="'viewUpdateUserForm'">
        <div class="card">
          <div class="card-header d-flex align-items-center">
            <h4>Edit Merchant Alias</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <form [formGroup]="merchantForm" (ngSubmit)="updateMerchant(merchantForm.value)" class="text-center">
                <div class="row">
                  <div class="col-md-6">
                    <div class="md-form">
                      <label for="aliasId" style="float: left">Alias Id</label>
                      <input formControlName="aliasId" type="text" id="aliasId" class="form-control">
                      <!-- <div *ngIf="(submitted && f.email.errors) || merchantForm.get('email').hasError('required') && merchantForm.get('email').touched"
                  class="invalid-feedback">
                  <div *ngIf="f.email.errors.required" class="align-text-left">Email is required</div>
                </div> -->
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="md-form">
                      <label for="merchantId" style="float: left">Merchant Id</label>
                      <input formControlName="merchantId" type="text" id="merchantId" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="md-form">
                      <label for="merchantCategoryCode" style="float: left">Merchant Category Code</label>
                      <input formControlName="merchantCategoryCode" type="text" id="merchantCategoryCode" class="form-control">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="md-form">
                      <label for="payloadFormatIndicator" style="float: left">Payload Format Indicator</label>
                      <input formControlName="payloadFormatIndicator" type="text" id="payloadFormatIndicator" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="md-form">
                      <label for="pointOfInitiationMethod" style="float: left">Point Of Initiation Method</label>
                      <select class="form-control" id="pointOfInitiationMethod" formControlName="pointOfInitiationMethod">
                        <option value="bankCode" disabled selected>Select Method</option>
                        <option value="QR_STATIC_CODE" style="color: black !important;" >QR_STATIC_CODE</option>
                        <option value="QR_DYNAMIC_CODE" style="color: black !important;" >QR_DYNAMIC_CODE</option>
                        <option value="BLE_STATIC_CODE" style="color: black !important;" >BLE_STATIC_CODE</option>
                        <option value="NFC_STATIC_CODE" style="color: black !important;" >NFC_STATIC_CODE</option>
                        <option value="NFC_DYNAMIC_CODE" style="color: black !important;" >NFC_DYNAMIC_CODE</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="md-form">
                      <label for="transactionCurrencyCode" style="float: left">Transaction Currency Code</label>
                      <input formControlName="transactionCurrencyCode" type="text" id="transactionCurrencyCode" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="md-form">
                      <label for="recipientName" style="float: left">Recipient Name</label>
                      <input formControlName="recipientName" type="text" id="recipientName" class="form-control">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="md-form">
                      <label for="city" style="float: left">City</label>
                      <input formControlName="city" type="text" id="city" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="md-form">
                      <label for="country" style="float: left">Country</label>
                      <input formControlName="country" type="text" id="country" class="form-control">
                    </div>
                  </div>
                </div>
                <!-- <div class="form-group">
                  <label for="materialLoginFormEmail" style="float: left;">Role</label>
                <select class="form-control" id="roleId" formControlName="roleId">
                  <option value="" selected>Select Role</option>
                  <option *ngFor="let category of categories" [value]="category.uniqueKey">{{ category.name }} ( {{
                    category.description }} )</option>
                </select>
              </div> -->
                <br />
                <button style="float: left; margin-right: 10px;" class="btn btn-secondary" data-dismiss="modal"
                  aria-label="Close" type="button" (click)="close()">Close</button>&nbsp;&nbsp;&nbsp;
                <button style="float: left; margin-left: 10px;" class="btn btn-primary" type="submit">Update</button> &nbsp;&nbsp;&nbsp;
               <span style="clear: both;"></span>&nbsp;&nbsp;&nbsp;
                <button style="float: left;" class="btn btn-danger" type="button" (click)="deleteMerchant()">Delete</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="modal fade" id="inviteUserForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">Create Merchant</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card-body">
          <div class="container-fluid">
            <form [formGroup]="merchantForm" (ngSubmit)="createMerchant(merchantForm.value)" class="text-center">
              <div class="row">
                <div class="col-md-6">
                  <div class="md-form">
                    <label for="aliasId" style="float: left">Alias Id</label>
                    <input formControlName="aliasId" type="text" id="aliasId" class="form-control">
                    <!-- <div *ngIf="(submitted && f.email.errors) || merchantForm.get('email').hasError('required') && merchantForm.get('email').touched"
                class="invalid-feedback">
                <div *ngIf="f.email.errors.required" class="align-text-left">Email is required</div>
              </div> -->
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="md-form">
                    <label for="merchantId" style="float: left">Merchant Id</label>
                    <input formControlName="merchantId" type="text" id="merchantId" class="form-control">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="md-form">
                    <label for="merchantCategoryCode" style="float: left">Merchant Category Code</label>
                    <input formControlName="merchantCategoryCode" type="text" id="merchantCategoryCode" class="form-control">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="md-form">
                    <label for="payloadFormatIndicator" style="float: left">Payload Format Indicator</label>
                    <input formControlName="payloadFormatIndicator" type="text" id="payloadFormatIndicator" class="form-control">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="md-form">
                    <label for="pointOfInitiationMethod" style="float: left">Point Of Initiation Method</label>
                    <select class="form-control" id="pointOfInitiationMethod" formControlName="pointOfInitiationMethod">
                      <option value="bankCode" disabled selected>Select Method</option>
                      <option value="QR_STATIC_CODE" style="color: black !important;" >QR_STATIC_CODE</option>
                      <option value="QR_DYNAMIC_CODE" style="color: black !important;" >QR_DYNAMIC_CODE</option>
                      <option value="BLE_STATIC_CODE" style="color: black !important;" >BLE_STATIC_CODE</option>
                      <option value="NFC_STATIC_CODE" style="color: black !important;" >NFC_STATIC_CODE</option>
                      <option value="NFC_DYNAMIC_CODE" style="color: black !important;" >NFC_DYNAMIC_CODE</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="md-form">
                    <label for="transactionCurrencyCode" style="float: left">Transaction Currency Code</label>
                    <input formControlName="transactionCurrencyCode" type="text" id="transactionCurrencyCode" class="form-control">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="md-form">
                    <label for="recipientName" style="float: left">Recipient Name</label>
                    <input formControlName="recipientName" type="text" id="recipientName" class="form-control">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="md-form">
                    <label for="city" style="float: left">City</label>
                    <input formControlName="city" type="text" id="city" class="form-control">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="md-form">
                    <label for="country" style="float: left">Country</label>
                    <input formControlName="country" type="text" id="country" class="form-control">
                  </div>
                </div>
              </div>
              <!-- <div class="form-group">
                <label for="materialLoginFormEmail" style="float: left;">Role</label>
              <select class="form-control" id="roleId" formControlName="roleId">
                <option value="" selected>Select Role</option>
                <option *ngFor="let category of categories" [value]="category.uniqueKey">{{ category.name }} ( {{
                  category.description }} )</option>
              </select>
            </div> -->
              <br />
              <button style="float: left; margin-right: 10px;" class="btn btn-secondary" data-dismiss="modal"
                aria-label="Close" type="button" (click)="close()">Close</button>&nbsp;&nbsp;&nbsp;
              <button style="float: left" class="btn btn-primary" type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="text-center">
<a href="" class="btn btn-default btn-rounded mb-4"  data-toggle="modal" data-target="#inviteUserForm"(click)="displayInvitUserForm()">
Create User</a>
</div> -->
  <!-- </div> -->
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
