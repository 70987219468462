0<nav class="side-navbar">
  <div class="side-navbar-wrapper">
    <!-- Sidebar Header    -->
    <div class="sidenav-header d-flex align-items-center justify-content-center">
      <!-- User Info-->
      <div class="sidenav-header-inner text-center">
        <div class="img-fluid rounded-circle"
          style="height: 60px; width: 60px; background: wheat;margin-left: 20px; font-weight: 900; font-size: 2.5rem;">B
        </div>
        <!-- <h2 class="h5"> -->
          <!-- <p>{{ fullName }}</p>
        </h2><span>{{ user.roleName }}</span> -->
      </div>
      <!-- Small Brand information, appears on minimized sidebar-->
      <div class="sidenav-header-logo">
        <a routerLink="/dashboard" class="brand-small text-center"> <strong>X</strong>
          <strong class="text-primary"></strong>
        </a>
      </div>
    </div>
    <!-- Sidebar Navigation Menus-->
    <div class="main-menu">
      <h5 class="sidenav-heading">Main</h5>
      <ul id="side-main-menu" class="side-menu list-unstyled">
          <li><a routerLink="/dashboard"> <i class="icon-home"></i>Home</a></li>
        <li><a routerLink="/merchant"> <i
              class="icon-form"></i>Merchant</a></li>

        <!-- <li *ngIf="menu.indexOf('bank_index') !=-1"><a routerLink="/bank/view"> <i class="icon-grid"></i>Bank</a></li> -->
        <li><a  routerLink="/logs"> <i
              class="icon-grid"></i>Transaction</a></li>
        <li><a (click)="logOut()"> <i class="fa fa-power-off"></i>logout</a></li>
      </ul>
    </div>
    <!-- <div class="admin-menu">
      <h5 class="sidenav-heading">Second menu</h5>
      <ul id="side-admin-menu" class="side-menu list-unstyled">
        <li> <a href="#"> <i class="icon-screen"> </i>Demo</a></li>
        <li> <a href="#"> <i class="icon-flask"> </i>Demo
            <div class="badge badge-info">Special</div></a></li>
        <li> <a href=""> <i class="icon-flask"> </i>Demo</a></li>
        <li> <a href=""> <i class="icon-picture"> </i>Demo</a></li>
      </ul>
    </div> -->
  </div>
</nav>
<div class="page">
  <!-- navbar-->
  <header class="header">
    <nav class="navbar">
      <div class="container-fluid">
        <div class="navbar-holder d-flex align-items-center justify-content-between">
          <div class="navbar-header">
            <a id="toggle-btn"  (click)="toggleMenu()"  class="menu-btn"><i class="icon-bars"> </i></a>
            <a routerLink="/dashboard" style="color: black;" class="navbar-brand">
              <div class="brand-text d-none d-md-inline-block">
                <!-- <span>{{ user.businessName }}</span>  -->
                <!-- <strong class="text-primary">Dashboard</strong> -->
              </div>
            </a></div>
          <ul class="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">
            <!-- Notifications dropdown-->
<!--            <li class="nav-item"><a href="{{ docs }}" target="_blank" style="color:black;" class="nav-link logout"> <span-->
<!--                  class="d-none d-sm-inline-block" style="color: #000000 !important;">Documentation</span>-->
<!--                <i class="fas fa-file"></i></a></li>-->
            <!-- <li class="nav-item dropdown"> <a id="notifications" rel="nofollow" data-target="#" href="#"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link"><i
                  class="fa fa-bell"></i><span class="badge badge-warning">12</span></a>
            </li> -->
            <li class="nav-item" (click)="logOut()" style="color: black !important;"><a class="nav-link logout"> <span
                  class="d-none d-sm-inline-block" style="color: #000000 !important;">Logout</span><i class="fa fa-power-off"></i></a></li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
  <router-outlet></router-outlet>
  <footer class="main-footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          <p>BluPay &copy; 2019</p>
        </div>
      </div>
    </div>
  </footer>
</div>
