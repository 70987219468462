<div class="activate-user">
  <div class="container" style="padding-top:120px;">
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <div class="login-logo-wrapper">
          <!-- <img class="logo" src="../../../assets/images/not_logo.png" /> -->
        </div>
        <div [ngSwitch]="whichForm">
          <div class="card" style="min-height: 350px;" *ngSwitchCase="'viewResetPasswordForm'">
            <h5 class="card-header info-color white-text text-center py-4">
              <strong>Set Password</strong>
            </h5>
            <div class="card-body">
              <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword(resetPasswordForm.value)" class="text-center">
                <div class="form-group">
                  <label for="materialLoginFormPassword" style="float: left;">Password</label>
                  <input appRegisterInputValidator formControlName="password" type="password" id="materialLoginFormPassword"
                    class="form-control">
                    <password-strength-meter [password]="password.value" (strengthChange)="onPasswordStrengthChanged($event)"></password-strength-meter>
                  <div style="float: left; color: red; font-size: 14px; margin-top: 1px;"  *ngIf="(submitted && f.password.errors) || resetPasswordForm.get('password').hasError('required') && resetPasswordForm.get('password').touched"
                    class="invalid-feedback">
                    <div *ngIf="f.password.errors.required" class="align-text-left">Password is required</div>
                    <div style="float: left; color: red; font-size: 14px;"  *ngIf="submitted && resetPasswordForm.get('password').hasError('invalidPassword') ">
                      Passwords must have at least 7 characters and  must contain an uppercase letter, lowercase letter, numbers, and special character
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="materialLoginFormConfirmPassword" style="float: left;">Confirm Password</label>
                  <input appRegisterInputValidator formControlName="confirmPassword" type="password" id="materialLoginFormConfirmPassword"
                    class="form-control">
                  <div *ngIf="(submitted && f.confirmPassword.errors) || resetPasswordForm.get('confirmPassword').hasError('required') && resetPasswordForm.get('confirmPassword').touched"
                    class="invalid-feedback">
                    <div *ngIf="f.confirmPassword.errors.required" class="align-text-left">Confirm Password is required</div>
                  </div>
                </div>
                <!-- <button [disabled]="!resetPasswordForm.valid" class="btn btn-outline-info btn-rounded btn-block my-4 waves-effect z-depth-0"
                  type="submit">submit</button> -->
                <div class="form-group" style="float: left;">
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </form>
              <i (click)="backToHome()" class="fas fa-home" style="float: right;" title="back to home page"></i>
              <div style="clear: both;"></div>
            </div>
          </div>
        </div>
        <div [ngSwitch]="whichForm">
          <div class="card" style="min-height: 390px;" *ngSwitchCase="'viewSetPasswordForm'">
            <h5 class="card-header info-color white-text text-center py-4">
              <strong>Set Password</strong>
            </h5>
            <div class="card-body">
              <form [formGroup]="setPasswordForm" (ngSubmit)="setPassword(setPasswordForm.value)" class="text-center">
                <div class="form-group">
                  <label for="materialLoginFormEmail" style="float: left;">E-mail</label>
                  <input formControlName="email" type="email" id="materialLoginFormEmail" class="form-control">
                  <div *ngIf="submitted && setPasswordForm.get('email').hasError('required') && setPasswordForm.get('email').touched"
                    class="invalid-feedback">Email is required
                  </div>
                </div>
                <div class="form-group">
                  <label for="materialLoginFormPassword" style="float: left;">Password</label>
                  <input appRegisterInputValidator formControlName="password" type="password" id="materialLoginFormPassword"
                    class="form-control">
                    <password-strength-meter [password]="password.value" (strengthChange)="onPasswordStrengthChanged($event)"></password-strength-meter>
                  <div style="float: left; color: red; font-size: 14px; margin-top: 1px;"  *ngIf="submitted && resetPasswordForm.get('password').hasError('required') && resetPasswordForm.get('password').touched"
                    class="invalid-feedback">
                    Password is required
                  </div>
                  <div style="float: left; color: red; font-size: 14px;"  *ngIf="submitted && resetPasswordForm.get('password').hasError('invalidPassword') ">
                    Passwords must have at least 7 characters and  must contain an uppercase letter, lowercase letter, numbers, and symbols
                  </div>
                </div>
                <div class="form-group">
                  <label for="materialLoginFormConfirmPassword" style="float: left;">Confirm Password</label>
                  <input appRegisterInputValidator formControlName="confirmPassword" type="password" id="materialLoginFormConfirmPassword"
                    class="form-control">
                  <div *ngIf="submitted && resetPasswordForm.get('confirmPassword').hasError('required') && resetPasswordForm.get('confirmPassword').touched"
                    class="invalid-feedback">
                    Confirm Password is required
                  </div>
                </div>
                <!-- <button [disabled]="!emailForm.valid" class="btn btn-outline-info btn-rounded btn-block my-4 waves-effect z-depth-0"
                  type="submit">submit</button> -->
                <div class="form-group" style="float: left;">
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </form>
              <div style="clear: both;"></div>
              <i (click)="backToHome()" class="fas fa-home" style="float: right;" title="back to home page"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
