<div class="forgot-password">
  <div class="container" style="padding-top:120px;">
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <div [ngSwitch]="whichForm">
          <div class="card" style="height: 300px;" *ngSwitchCase="'viewForgotPasswordForm'">
            <h2 class="card-header info-color white-text text-center py-1" style="border-top-right-radius: 3px; border-top-left-radius: 3px; background: #222222 !important;">
              <img  height="50" class="logo" src="../../../assets/images/logo-1.png" />
              <br/>
              <strong style="color: #fff;">Sign In</strong>
            </h2>
            <div class="card-body">
              <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()" class="text-center">
                <div class="form-group">
                  <label style="float: left;">E-mail </label>
                  <input type="email" formControlName="email" placeholder="Email Address" class="form-control">
                </div>
                <div class="form-group" style="float: left;">
                    <button type="submit" class="btn btn-primary">Submit</button>
                  </div>
                <!-- <button [disabled]="!forgotPasswordForm.valid" class="btn btn-outline-info btn-rounded btn-block my-4 waves-effect z-depth-0"
                  type="submit">submit</button> -->
              </form>
              <i (click)="backToHome()" class="fas fa-home" style="float: right;" title="back to home page"></i>
            </div>
          </div>
          <div class="card" style="min-height: 350px;" *ngSwitchCase="'viewResetPasswordForm'">
            <h5 class="card-header info-color white-text text-center py-4">
              <strong>Update Password</strong>
            </h5>
            <div class="card-body">
              <div class="form-group">
                  <label for="username" style="float: left;">Email</label>
                <input readonly value="{{ email }}" type="text" id="username" class="form-control">
              </div>
              <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPAssword(resetPasswordForm.value)" class="text-center">
                
                <div class="form-group">
                    <label for="materialLoginFormPassword" style="float: left;">Password</label>
                  <input appRegisterInputValidator formControlName="password" type="password" id="materialLoginFormPassword"
                    class="form-control">
                    <password-strength-meter [password]="password.value" (strengthChange)="onPasswordStrengthChanged($event)"></password-strength-meter>
                  <div style="float: left; color: red; font-size: 14px; margin-top: 1px;"  *ngIf="(submitted && f.password.errors) || resetPasswordForm.get('password').hasError('required') && resetPasswordForm.get('password').touched"
                    class="invalid-feedback">
                    <div *ngIf="f.password.errors.required" class="align-text-left">Password is required</div>
                    <div style="float: left; color: red; font-size: 14px;"  *ngIf="appName.errors.invalidPassword">
                    <!-- <div style="float: left; color: red; font-size: 14px;"  *ngIf="resetPasswordForm.get('password').hasError('invalidPassword')"> -->
                      Passwords must have at least 7 characters and must contain an uppercase letter, lowercase letter, numbers,  and special character
                    </div>
                  </div>
                </div>
                <div class="form-group">
                    <label for="materialLoginFormConfirmPassword" style="float: left;">Confirm Password</label>
                  <input appRegisterInputValidator formControlName="confirmPassword" type="password" id="materialLoginFormConfirmPassword"
                    class="form-control">
              <div *ngIf="(submitted && f.confirmPassword.errors) || resetPasswordForm.get('confirmPassword').hasError('required') && resetPasswordForm.get('confirmPassword').touched"
                    class="invalid-feedback">
                    <div *ngIf="f.confirmPassword.errors.required" class="align-text-left">Confirm Password is required</div>
                  </div>    
                </div>
                <div class="form-group" style="float: left;">
                <button class="btn btn-primary" type="submit">submit</button>
                </div>
                <div style="clear: both;"></div>
              </form>
              <i (click)="backToHome()" class="fas fa-home" style="float: right;" title="back to home page"></i>
            </div>
            
          </div>
          
        </div>
        
      </div>
      
    </div>
    
  </div>
  
</div>
<ngx-spinner
bdColor="rgba(51,51,51,0.8)"
size="medium"
color="#fff"
type="ball-scale-multiple">
<p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
