    <nav class="navbar navbar-expand-lg navbar-dark fixed-top" style="background: #000000 !important">
      <a class="navbar-brand" [routerLink]="['/home']">
        <img  alt="xpresspayoneline logo" src="../../../assets/images/logo-1.png" />
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse"
        data-target="#basicExampleNav" aria-controls="basicExampleNav" aria-expanded="false"
        aria-label="Toggle navigation">
        <i class="fa fa-bars"></i>
        <!-- <span class="navbar-toggler-icon"></span> -->
      </button>

      <div class="collapse navbar-collapse" id="basicExampleNav">
        <ul class="nav navbar-nav ml-auto float-right">
          <li class="nav-item active">
            <a class="nav-link" routerLink="home">HOME
                <span class="sr-only">(current)</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/login">LOGIN</a>
          </li>
<!--          <li class="nav-item">-->
<!--            <a class="nav-link" routerLink="/register">CREATE-->
<!--                ACCOUNT</a>-->
<!--          </li>-->
        </ul>
      </div>
    </nav>
